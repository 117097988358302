.HomePage {
    gap: 1rem;
    width: 55%;
    margin-top: 5rem;
    min-height: 98.1vh;
    margin-bottom: 0rem;
    display: inline-flex;
    flex-direction: column;
}

.WelcomeMsgContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    animation-delay: 2s;
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
}

.WelcomeMsgTextContainer {
    gap: 1rem;
    width: 100%;
    display: flex;
    font-family: Righteous;
    flex-direction: column;
}

.WelcomeMsg1Container,
.WelcomeMsg2Container,
.WelcomeMsg3Container {
    gap: 0.5rem;
    width: 100%;
    display: flex;
    font-size: 2rem;
    color: #24354B;
    flex-direction: row;
    justify-content: flex-start;
}

.WelcomeMsg2Container {
    font-size: 1.7rem;
}

.WelcomeMsg3Container {
    gap: 1rem;
    /* width: 28rem; */
    margin-top: 1rem;
    font-size: 2.5rem;
    align-items: center;
}

.waving {
    transform-origin: 70% 70%;
    animation: wave 2.5s infinite;
    -webkit-animation: wave 2.5s infinite;
}

.WelcomeMsg2_1,
.RTE1 {
    color: orange;
}

.WelcomeMsg3_1 {
    font-size: 4.5rem;
}

.profileImage {
    transition: 1s;
    max-height: 15rem;
    border-radius: 1rem;
    border-style: solid;
    border-width: 0.1rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.profileImage:hover {
    transition: 2s;
    animation: wobble-profile 0.8s both;
    -webkit-animation: wobble-profile 0.8s both;
}

.scrolling-wrapper,
.contactContainer {
    gap: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.carouselHL,
.contactTitle {
    gap: 0.5rem;
    display: flex;
    color: orange;
    font-size: 2rem;
    flex-direction: row;
    font-family: Righteous;
    text-align: center;
}

.contactContainer {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s both;
}

.contactTitle1 {
    color: #24354B;
}

.contactContainer2 {
    gap: 1rem;
    display: flex;
    flex-direction: row;
}

.scrolling-wrapper {
    padding-bottom: 1rem;
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
}

.photoStyle {
    border: solid;
    height: 280px;
    border-radius: 3rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: rgba(0, 0, 0, 0.116);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.slick-slider {
    max-width: 90%;
}

.slick-slide>div {
    padding: 0 0.5rem;
}

.slick-arrow {
    width: auto;
    height: auto;
}

.slick-prev {
    left: -2.7rem !important;
    z-index: 500;
}

.slick-next {
    right: -3.2rem !important;
    z-index: 500;
}

.slick-next::before,
.slick-prev::before {
    font-size: 3rem;
    color: black;
}

.homeA {
    color: #24354B;
    text-decoration: none;
}

.ExtInf {
    border: solid;
    display: flex;
    overflow: hidden;
    border-radius: 1rem;
    flex-direction: row;
}

.InfIcon {
    width: 3.5rem;
    color: ivory;
    font-size: 2rem;
    justify-content: center;
    background-color: #24354B;
}

.InfTxt,
.InfTxtExt {
    display: flex;
    text-wrap: nowrap;
    align-items: center;
    font-family: Righteous;
}

.InfTxt {
    transition: 0.5s;
    font-size: 0rem;
}

.InfTxtExt {
    transition: 1s;
    font-size: 1.4rem;
    padding: 0rem 1rem;
}

@media (max-width: 1200px) {
    .HomePage {
        width: 70%;
        margin-bottom: 2rem;
    }

    .WelcomeMsgContainer {
        gap: 1rem;
        align-items: center;
        flex-direction: column;
    }

    .WelcomeMsg1Container,
    .WelcomeMsg2Container,
    .WelcomeMsg3Container {
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .HomePage {
        width: 90%;
    }

    .WelcomeMsg3_1 {
        font-size: 4rem;
    }

    .WelcomeMsg3Container {
        font-size: 2rem;
    }

    .slick-slider {
        max-width: 80%;
    }

    .contactContainer2 {
        flex-direction: column;
    }

    .InfTxt {
        padding: 0 1rem;
        font-size: 1.4rem;
    }
}

@media (max-width: 400px) {
    .WelcomeMsg3_1 {
        font-size: 3.5rem;
    }

    .WelcomeMsg1Container{
        font-size: 1.5rem;
    }

    .WelcomeMsg2Container, .WelcomeMsg3Container {
        font-size: 1.2rem;
    }

    .slick-slider {
        max-width: 75%;
    }

    .InfTxt {
        font-size: 1rem;
    }
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(14deg);
    }

    20% {
        transform: rotate(-8deg);
    }

    30% {
        transform: rotate(14deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes wave {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(14deg);
    }

    20% {
        transform: rotate(-8deg);
    }

    30% {
        transform: rotate(14deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes wobble-profile {

    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-30px) rotate(-6deg);
        transform: translateX(-30px) rotate(-6deg);
    }

    30% {
        -webkit-transform: translateX(15px) rotate(6deg);
        transform: translateX(15px) rotate(6deg);
    }

    45% {
        -webkit-transform: translateX(-15px) rotate(-3.6deg);
        transform: translateX(-15px) rotate(-3.6deg);
    }

    60% {
        -webkit-transform: translateX(9px) rotate(2.4deg);
        transform: translateX(9px) rotate(2.4deg);
    }

    75% {
        -webkit-transform: translateX(-6px) rotate(-1.2deg);
        transform: translateX(-6px) rotate(-1.2deg);
    }
}

@keyframes wobble-profile {

    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-30px) rotate(-6deg);
        transform: translateX(-30px) rotate(-6deg);
    }

    30% {
        -webkit-transform: translateX(15px) rotate(6deg);
        transform: translateX(15px) rotate(6deg);
    }

    45% {
        -webkit-transform: translateX(-15px) rotate(-3.6deg);
        transform: translateX(-15px) rotate(-3.6deg);
    }

    60% {
        -webkit-transform: translateX(9px) rotate(2.4deg);
        transform: translateX(9px) rotate(2.4deg);
    }

    75% {
        -webkit-transform: translateX(-6px) rotate(-1.2deg);
        transform: translateX(-6px) rotate(-1.2deg);
    }
}