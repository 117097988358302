/* * {
    box-sizing: border-box;
} */

body {
    margin: 0;
    padding: 0;
    width: 100%;
}

.aLine {
    width: 100%;
    height: 45px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    /* border-top: 2px solid #bbb;
    border-radius: 5px; */
}

.aLine::after,
.aLine::before {
    top: 45%;
    width: 45%;
    height: 2px;
    margin: auto;
    content: '';
    position: absolute;
    background: #bbb;
}

.aLine::after {
    left: 0
}

.aLine::before {
    right: 0
}

.logoLine {
    max-width: 15px;
}

.logoLineBorder{
    border-top: solid;
    border-bottom: solid;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    border-radius: 5rem;
    border-color: #bbb;
    border-width: 0.15rem;
    justify-content: center;
}

.blinkersOrange {
    animation: blink 1s linear infinite;
}

.container {
    display: flex;
    justify-content: center;
    background-image: url("../../pages/img/beige-paper.png");
}

@keyframes blinkOrange {
    50% {
        background-color: orange;
    }
}

@keyframes blinkOrangeTxt {
    50% {
        color: orange;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}