.ProjectPage {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: 2rem;
    align-items: center;
    flex-direction: row;
    padding-bottom: 2rem;
    justify-content: center;
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
}

.ProjectContainer {
    display: flex;
    max-width: 20rem;
    padding-top: 1rem;
    align-items: center;
    border-style: solid;
    border-radius: 1rem;
    border-width: 0.1rem;
    flex-direction: column;
    justify-content: center;
    transition: 1s ease-in-out;
    border-color: rgba(0, 0, 0, 0.116);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ProjectContainer1 {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ProjectInfoImg {
    width: 18rem;
    height: 200px;
    object-fit: contain;
}

.ProjectInfoTitle {
    height: 4rem;
    color: orange;
    font-size: 1.5rem;
    font-family: Viga;
    text-align: center;
    border-bottom: solid;
    border-width: 0.15rem;
}

.ProjectContainerPadding {
    padding-top: 2rem;
}

.ProjectInfoContainer {
    float: left;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ProjectInfoContainer1 {
    height: 13rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.ProjectInfoContainer2 {
    gap: 0.5rem;
    display: flex;
    min-height: 7rem;
    padding-top: 1rem;
    align-items: center;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
}

.ServerStatusColour {
    color: red;
    display: flex;
    text-align: justify;
    align-items: center;
}

.ProjectInfoButton,
.ProjectInfoButtonIA {
    width: 90%;
    height: 100%;
    border: solid;
    display: flex;
    font-size: 1.3rem;
    color: #c5c5c5d2;
    border-radius: 0.5rem;
    text-decoration: none;
    justify-content: center;

}

.ProjectInfoButton {
    transition: 1s;
    color: #24354B;
    border-width: 0.1rem;
    font-family: VarelaRound;
    background-color: rgba(233, 233, 233, 0.788);
}

.ProjectInfoButton:hover {
    color: orange;
    transition: 0.5s;
    overflow: visible;
    transform: scale(1.05);
    box-shadow: 2em 2em 3em -3em rgba(0, 0, 0, .5),
        1em 2em 3.5em -2.5em rgba(0, 0, 0, .5);
}

.AnotherPP {
    /* width: 100%; */
    padding: 0.5rem;
    text-align: justify;
    font-family: Righteous;
}