.site-title {
    float: left;
}

.site-title-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.site-title-initial {
    color: ivory;
    font-size: 2rem;
    letter-spacing: 0.3rem;
    font-family: VarelaRound;
}

.site-title-back {
    font-size: 2rem;
    color: #A2B4BF;
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-family: VarelaRound;
}

.nav-container {
    width: 100%;
    z-index: 998;
    padding: 0 5rem;
    background-color: #24354B;
}

.nav {
    display: flex;
    top: 0;
    gap: 2rem;
    width: 100%;
    /* position: absolute; */
    position: fixed;
    z-index: 700;
    color: ivory;
    align-items: center;
    background-color: #24354B;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.nav ul {
    gap: 3rem;
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    transition: 1.5s;
    font-family: VarelaRound;
}

.nav li {
    padding: 0.25rem;
    border-radius: 0.5rem;
}

.navLinks {
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
    display: inline-flex;
}

.nav li.active {
    transition: 0.4s;
    display: inline-block;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    text-decoration-color: orange;
    text-decoration-thickness: 0.15rem;
}

.nav li:hover {
    color: orange;
    transform: scale(1.1);
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    transform-origin: bottom right;
    text-decoration-color: orange;
    text-decoration-thickness: 0.15rem;
    transition: transform 0.4s ease-in;
}

.nav a {
    height: 100%;
    color: inherit;
    display: block;
    padding: .25rem;
    font-weight: bold;
    align-items: center;
    text-decoration: none;
}

.toggle-button {
    right: 1rem;
    width: 30px;
    top: 0.75rem;
    height: 22px;
    display: none;
    cursor: pointer;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
}

.bar {
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background-color: #D1DBE7;
    animation: blinkOrange linear 1s infinite;
}

.expanded {
    padding-right: 5rem;
}

.footer {
    width: 100%;
    display: flex;
    color: ivory;
    position: absolute;
    justify-content: center;
    background-color: #24354B;
}

.loadingImg {
    max-height: 10rem;
}

@media (max-width: 1100px) {
    .nav-container {
        padding: 0 1rem;
    }

    .toggle-button {
        display: flex;
    }

    .nav .expanded {
        width: 100%;
    }

    .nav {
        color: #24354B;
        flex-direction: column;
        align-items: flex-start;
    }

    .nav ul {
        width: 100%;
        gap: 0.15rem;
        flex-direction: column;
    }

    .nav li {
        padding: 0rem;
        text-align: center;
    }

    .nav.active {
        display: flex;
    }

    .nav.nav.active {
        display: flex;
    }

    .nav li.active {
        color: #FF7300;
        text-decoration: none;
        background-color: #F8F3E8F5;
    }

    .nav li:hover {
        color: #24354B;
        text-decoration: none;
        transform-origin: center;
        background-color: #F8F3E8;
    }

    .expanded {
        left: 0;
        /* top: -1000px; */
        width: 100%;
        padding: 3.5rem 0rem 0rem;
        /* position: absolute;
        z-index: 997; */
        overflow: hidden;
        position: absolute;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        background-color: #D1DBE7F5;
    }
}

@-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}

@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}

@-webkit-keyframes slide-out-nav {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
    }
}

@keyframes slide-out-nav {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
    }
}