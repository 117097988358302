.SkillsPage {
    width: 100%;
    display: flex;
    padding-top: 5rem;
    align-items: center;
    flex-direction: column;
}

.SkillsPage > div:nth-child(1) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
}

.SkillsPage > div:nth-child(2) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.5s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.5s both;
}

.SkillsPage > div:nth-child(3) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
}

.SkillsPage > div:nth-child(4) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1.5s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1.5s both;
}

.SkillsPage > div:nth-child(5) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s both;
}

.SkillsPage > div:nth-child(6) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2.5s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2.5s both;
}

.SkillsPage > div:nth-child(7) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 3s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 3s both;
}

.SkillsPage > div:nth-child(8) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 3.5s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 3.5s both;
}

.skillsTitle {
    color: orange;
    font-size: 2.5rem;
    font-family: Viga;
    text-align: center;
}

.skillsContainer {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.skillsContainer1 {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    align-items: center;
    padding-bottom: 2rem;
    justify-content: center;
}

.skillsLogoContainer, .skillsLogoContainer1 {
    gap: 0.5rem;
    float: left;
    display: flex;
    padding: 0 0.5rem;
    padding-top: 1rem;
    align-items: center;
    border-style: solid;
    border-radius: 1rem;
    border-width: 0.1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: center;
    border-color: #24354B;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.skillsLogoContainer {
    width: 120px;
}

.skillCont {
    width: 100px;
    display: flex;
    justify-content: center;
}

.skillsLogo {
    height: 100px;
    text-align: center;
}

.skillsNameTextExt{
    cursor: default;
    font-size: 1rem;
    color: #24354B;
    padding: 0 0.5rem;
    font-family: Righteous;
}

.skillsNameExt {
    display: flex;
    font-size: 1rem;
    color: #24354B;
    max-width: 100px;
    transition: 0.5s;
    text-align: center;
    white-space: pre-line;
    font-family: Righteous;
    justify-content: center;
}

.skillsName {
    font-size: 0rem;
    color: #24354B;
    transition: 0.5s;
    text-align: center;
    font-family: Righteous;
}

@media (max-width: 550px) {
    .skillsContainer {
        width: 90%;
    }
}