.AboutPage {
    gap: 1rem;
    width: 70%;
    margin-top: 5rem;
    min-height: 98.1vh;
    display: inline-flex;
    margin-bottom: 1.5rem;
    flex-direction: column;
}

.AboutPage div:nth-child(1) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s both;
}

.AboutPage div:nth-child(2) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
}

.AboutPage div:nth-child(3) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s both;
}

.AboutPage div:nth-child(4) {
    animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 3s both;
    -webkit-animation: fadeIn 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) 3s both;
}

.interestContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.interestMsg {
    display: flex;
    color: #24354B;
    flex-direction: column;
}

.interestMsgTitle {
    display: flex;
    color: orange;
    font-size: 3rem;
    font-weight: bold;
    font-family: Viga;
    justify-content: center;
}

.interestMsgText {
    max-width: 46rem;
    font-size: 1.2rem;
    font-family: itim;
    padding-left: 6rem;
    text-align: justify;
}

.interestImg {
    display: inline-flex;
}

.intImg {
    height: 15rem;
    border-radius: 15rem;
    background-color: orange;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.intImg:hover {
    animation: bounce-top 2.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s both;
    -webkit-animation: bounce-top 2.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s both;
}

.studiesInfo {
    gap: 0.8rem;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.AbtInfoTitle {
    color: orange;
    font-size: 2rem;
    font-family: viga;
    text-align: center;
}

.AbtInfoContainer {
    display: flex;
    transition: 1s;
    padding: 0 1.5rem;
    padding-top: 1rem;
    border-radius: 3rem;
    padding-bottom: 2rem;
    flex-direction: column;
}

.AbtInfoContainer:hover {
    transition: 0.8s;
    border-top: solid;
    border-left: solid;
    border-width: 0.1rem;
    transform: scale(1.02);
    border-color: rgba(0, 0, 0, 0.116);
    box-shadow: 2em 2em 3em -3em rgba(0, 0, 0, .5),
        1em 2em 3.5em -2.5em rgba(0, 0, 0, .5);
}

.AbtInfoContainer2 {
    display: flex;
    padding-top: 2rem;
    align-items: center;
    flex-direction: row;
}

.AbtInfoLogoImg {
    width: 13rem;
    border-radius: 0.3rem;
}

.AbtInfoText {
    width: 100%;
    display: flex;
    text-align: center;
    padding-left: 3rem;
    flex-direction: column;
}

.AbtInfoContainer3,
.AbtInfoContainer4 {
    gap: 0.5rem;
    display: flex;
    font-size: 2rem;
    color: #24354B;
    /* flex-direction: row; */
    font-family: Righteous;
    justify-content: center;
}

.AbtInfoText1,
.AbtInfoText2 {
    text-wrap: nowrap;
    text-align: center;
}

@media (max-width: 1600px) {
    .AbtInfoContainer3,
    .AbtInfoContainer4 {
        font-size: 1.5rem;
    }
}

@media (max-width: 1300px) {
    .AboutPage {
        width: 80%;
    }
}

@media (max-width: 1300px) {
    .AbtInfoContainer3,
    .AbtInfoContainer4 {
        flex-direction: column;
    }
}

@media (max-width: 1000px) {
    .interestContainer {
        flex-direction: column;
    }

    .interestMsgText {
        padding-left: 0;
    }
}

@media (max-width: 800px) {
    .AbtInfoContainer2 {
        padding-top: 1rem;
        flex-direction: column;
    }

    .AbtInfoText {
        padding-left: 0;
    }
}

@media (max-width: 500px) {
    .interestMsgTitle {
        margin-top: 1rem;
        font-size: 2.5rem;
    }

    .interestMsgText {
        font-size: 1rem;
    }

    .AbtInfoTitle {
        font-size: 1.5rem;
    }

    .AbtInfoLogoImg {
        width: 8rem;
        border-radius: 0.2rem;
    }

    .AbtInfoContainer3,
    .AbtInfoContainer4 {
        font-size: 1rem;
    }
}

@-webkit-keyframes bounce-top {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    40% {
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    65% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    82% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    93% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

@keyframes bounce-top {
    0% {
        -webkit-transform: translateY(-45px);
        transform: translateY(-45px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    40% {
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    65% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    82% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    93% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    25%,
    55%,
    75%,
    87% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }
}